import { ProductType } from 'Component/Product/Product.config';
import SourceProductPrice from 'SourceComponent/ProductPrice/ProductPrice.component';
import { ReactElement } from 'Type/Common.type';

import './ProductPrice.override.style';

export * from 'SourceComponent/ProductPrice/ProductPrice.component';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    pricePreviewRenderMap = {
        [ProductType.SIMPLE]: this.renderDefaultPrice.bind(this),
        [ProductType.VIRTUAL]: this.renderDefaultPrice.bind(this),
        [ProductType.BUNDLE]: this.renderBundlePrice.bind(this),
        [ProductType.GROUPED]: this.renderGroupedPrice.bind(this),
        [ProductType.DOWNLOADABLE]: this.renderDefaultPrice.bind(this),
        [ProductType.CONFIGURABLE]: this.renderConfigurablePrice.bind(this),
    };

    priceLabelTypeMap = {
        [ProductType.SIMPLE]: __('Starting at'),
        [ProductType.VIRTUAL]: __('Starting at'),
        [ProductType.BUNDLE]: __('Starting at'),
        [ProductType.GROUPED]: __('Starting at'),
        [ProductType.DOWNLOADABLE]: __('Starting at'),
        [ProductType.CONFIGURABLE]: __('Start at'),
    };

    renderTierPrice(): ReactElement {
        const {
            tierPrice: {
                valueFormatted: tierPriceFormatted,
                value: tierPriceValue,
            },
            price: {
                finalPrice: {
                    value,
                } = {},
            } = {},
        } = this.props;

        if (!tierPriceFormatted || tierPriceValue >= (value || 0)) {
            return null;
        }
        // eslint-disable-next-line
        console.log('test');

        return (
            <p block="ProductPrice" elem="TierPrice">
                { __('Start at') }
                <strong>{ `${tierPriceFormatted}` }</strong>
            </p>
        );
    }
}

export default ProductPriceComponent;
